import React, { ReactElement } from 'react'
import { ArrowLeftIcon, Button, IconLogo } from '../elements'
import { HamburgerMenu } from '../grouping'
import { Component } from '../../types/components'
import { DEFAULT_BUTTON_CLASS } from '../elements/_config'

// eslint-disable-next-line import/prefer-default-export
export function Header({ theme, onBack }: Component.Header): ReactElement {
  return (
    <div className="c__header container u__hide-on-print">
      <div className="row align-center">
        <div className="col-33 c__back-wrapper">
          {onBack && (
            <Button
              variant="link"
              onClick={onBack}
              className={`${DEFAULT_BUTTON_CLASS}--icon ${DEFAULT_BUTTON_CLASS}--icon--left c__back`}
            >
              <ArrowLeftIcon /> Back
            </Button>
          )}
        </div>
        <div className="col-33 flex justify-center c__header__logo">
          <IconLogo />
        </div>
        <div className="col-33 flex justify-end">
          <HamburgerMenu theme={theme} />
        </div>
      </div>
    </div>
  )
}
