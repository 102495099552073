import React from 'react'
import { Grouping } from '../../types/grouping'
import InformationIcon from '../elements/icons/info'
import { DEFAULT_ALERT_CLASS } from './_config'

export default function Alert({ children, className = '' }: Grouping.Alert) {
  return (
    <div className={`${DEFAULT_ALERT_CLASS} ${className} flex align-center`}>
      <div className={`${DEFAULT_ALERT_CLASS}__icon`}>
        <InformationIcon />
      </div>
      <div className={`${DEFAULT_ALERT_CLASS}__content u__mc--vertical`}>{children}</div>
    </div>
  )
}
