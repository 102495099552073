import React, { createContext, useContext, useReducer } from 'react'
import { BottleSelectDispatchType } from '../types/enums'
import { BottleSelectState } from '../types/state'
import IsBrowser from '../utils/is-browser'

export const BOTTLE_SELECT_CONTEXT_BACKUP_KEY = 'bottle-select-state'

const defaultState: BottleSelectState.State = {
  bottles: [],
  requiresStepTwo: false,
}

const BottleSelectStateContext = createContext<BottleSelectState.State>(defaultState)
// eslint-disable-next-line unicorn/no-useless-undefined
const BottleSelectDispatchContext = createContext<BottleSelectState.Dispatch | undefined>(undefined)

function bottleSelectReducer(state: BottleSelectState.State, action: BottleSelectState.Action) {
  switch (action.type) {
    case BottleSelectDispatchType.enableStepTwo:
      if (IsBrowser()) {
        window.localStorage.setItem(BOTTLE_SELECT_CONTEXT_BACKUP_KEY, JSON.stringify(action.payload))
      }

      return action.payload
    case BottleSelectDispatchType.backToStepOne:
      return defaultState
    default:
      throw new Error(`Unhandled action type: ${(action as any).type}`)
  }
}

function BottleSelectProvider({ children }: BottleSelectState.BottleSelectProviderProperties) {
  const [state, dispatch] = useReducer(
    bottleSelectReducer,
    (() => {
      if (IsBrowser()) {
        const data: BottleSelectState.State | null = JSON.parse(
          window.localStorage.getItem(BOTTLE_SELECT_CONTEXT_BACKUP_KEY),
        )
        if (data !== null) {
          return data
        }
      }

      return defaultState
    })(),
  )

  return (
    <BottleSelectStateContext.Provider value={state}>
      <BottleSelectDispatchContext.Provider value={dispatch}>{children}</BottleSelectDispatchContext.Provider>
    </BottleSelectStateContext.Provider>
  )
}

function useBottleSelectState(): BottleSelectState.State {
  const context = useContext(BottleSelectStateContext)

  if (context === undefined) {
    throw new Error('useBottleSelectState must be used within a AppProvider')
  }

  return context
}

function useBottleSelectDispatch(): BottleSelectState.Dispatch {
  const context = useContext(BottleSelectDispatchContext)

  if (context === undefined) {
    throw new Error('useBottleSelectDispatch must be used within a AppProvider')
  }

  return context
}

export { BottleSelectProvider, useBottleSelectDispatch, useBottleSelectState }
