import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Page } from '../../types/page'
import { Header } from './header'
import RotateImg from '../../images/rotate-phone.svg'

import '../../styles/index.scss'
import 'keen-slider/keen-slider.min.css'
import IsBrowser from '../../utils/is-browser'
import IsMobile from '../../utils/is-mobile'

export default function PageLayout({
  children,
  lang = 'en',
  theme = 'light',
  className = '',
  onBack,
}: Page.PageLayout): ReactElement {
  const [isLandscapeMobile, setIsLandscapeMobile] = useState(false)
  const orientationMedia = useRef<MediaQueryList>(null)

  useEffect(() => {
    function handleOrientationChange(media: MediaQueryListEvent) {
      if (IsMobile() && media.matches && !(window.screen.availHeight > window.screen.availWidth)) {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        document.body.style.overflow = 'hidden'
        setIsLandscapeMobile(true)
      } else {
        document.body.style.overflow = 'visible'
        setIsLandscapeMobile(false)
      }
    }

    if (IsBrowser()) {
      orientationMedia.current = window?.matchMedia?.('(orientation: landscape)')
      orientationMedia?.current?.addEventListener?.('change', handleOrientationChange)
    }

    return () => {
      orientationMedia?.current?.removeEventListener?.('change', handleOrientationChange)
    }
  }, [])

  /**
   * @see https://stackoverflow.com/a/38573198
   */
  useEffect(() => {
    let lastTouchEnd = 0

    const onTouchMove = (event: any) => {
      const newEvent = event.orignalEvent || event
      if (newEvent?.scale > 1) {
        event.preventDefault()
      }
    }

    const onTouchEnd = (event: TouchEvent) => {
      const now = Date.now()

      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }

      lastTouchEnd = now
    }

    document.addEventListener('touchmove', onTouchMove, { passive: false })
    document.addEventListener('touchend', onTouchEnd, { passive: false })

    return () => {
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('touchend', onTouchEnd)
    }
  }, [])

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content="Glenfiddich Label Designer" />
      </Helmet>
      <div className={`overlay ${isLandscapeMobile ? 'overlay--visible' : ''}`}>
        <div className="overlay-image">
          <RotateImg style={{ display: 'inline-block', width: 100, height: 'auto', verticalAlign: 'middle' }} />.
        </div>
        <div className="overlay-text">Please rotate your device to portrait</div>
      </div>
      <div id="site-content" className={`${className} theme--${theme} ${IsMobile() ? 'is-mobile' : 'not-mobile'}`}>
        <Header onBack={onBack} theme={theme} />
        {children}
      </div>
    </>
  )
}
