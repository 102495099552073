import React, { useMemo } from 'react'
import { Grouping } from '../../types/grouping'

const titleIncludesNumbers = (title: string): boolean => {
  const titleArray = title.split(' ')
  const numberedWords = titleArray.filter((word) => !Number.isNaN(Number.parseInt(word, 10)))

  return numberedWords.length > 0
}

export default function DecoratedBottleTitle({
  className = '',
  title,
  element: Element = 'p',
}: Grouping.DecoratedBottleTitle) {
  const markup = useMemo(() => {
    if (!titleIncludesNumbers(title)) {
      return `<span>${title}</span>`
    }

    const titleArray = title.split(' ')
    return titleArray
      .map((word) => {
        const parsedNumber = Number.parseInt(word, 10)

        return Number.isNaN(parsedNumber) ? word : `<span>${word}</span>`
      })
      .join(' ')
  }, [title])

  return <Element className={`c__decorated-bottle-title ${className}`} dangerouslySetInnerHTML={{ __html: markup }} />
}
