import React, { MouseEvent, ReactElement } from 'react'
import { useLocation } from '@reach/router'
import { AnchorButton, List } from '../elements'
import { Grouping } from '../../types/grouping'
import { useAppState } from '../../state/app-context'
import { BOTTLE_SELECT_CONTEXT_BACKUP_KEY } from '../../state/bottle-select-context'

export default function MainMenu({ triggerClose, className = '' }: Grouping.MainMenu): ReactElement {
  const { pathname } = useLocation()
  const { appLanguage } = useAppState()

  const onClick = (path: string) => (event: MouseEvent<HTMLAnchorElement>) => {
    if (path === pathname) {
      event.preventDefault()
      triggerClose()
    } else {
      window.localStorage.removeItem(BOTTLE_SELECT_CONTEXT_BACKUP_KEY)
    }
  }

  return (
    <div className={`c__main-menu ${className}`}>
      <List type="ul" modifier="reset" className="text-alignment--center">
        <li className="animated">
          <AnchorButton
            className="c__main-menu__link"
            activeClassName="c__main-menu__link--active"
            variant="link"
            href={`/${appLanguage}/`}
            onClick={onClick(`/${appLanguage}/`)}
          >
            Home
          </AnchorButton>
        </li>
        <li className="animated animated-delay-2">
          <AnchorButton
            className="c__main-menu__link"
            activeClassName="c__main-menu__link--active"
            variant="link"
            href={`/${appLanguage}/setup/`}
            onClick={onClick(`/${appLanguage}/setup/`)}
          >
            Settings
          </AnchorButton>
        </li>
        <li className="animated animated-delay-4">
          <AnchorButton
            className="c__main-menu__link"
            activeClassName="c__main-menu__link--active"
            variant="link"
            href={`/${appLanguage}/help/`}
            onClick={onClick(`/${appLanguage}/help/`)}
          >
            Help
          </AnchorButton>
        </li>
      </List>
    </div>
  )
}
