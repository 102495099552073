import React, { ReactElement, useState } from 'react'
import { Grouping } from '../../types/grouping'
import { Button, HamburgerIcon } from '../elements'
import MainMenu from './main-menu'

export default function HamburgerMenu({ theme }: Grouping.HamburgerMenu): ReactElement {
  const [mainMenuIsOpen, setMainMenuIsOpen] = useState(false)

  const onHamburgerMenuClick = () => setMainMenuIsOpen(!mainMenuIsOpen)

  const onTriggerClose = () => setMainMenuIsOpen(false)

  return (
    <div className="c__hambuger-menu">
      <Button onClick={onHamburgerMenuClick} variant="link">
        <HamburgerIcon isOpen={mainMenuIsOpen} theme={mainMenuIsOpen ? 'light' : theme} />
      </Button>
      <MainMenu
        triggerClose={onTriggerClose}
        className={`flex justify-center align-center ${mainMenuIsOpen ? 'c__main-menu--open' : ''}`}
      />
    </div>
  )
}
