import React, { ReactElement } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useBottleSelectState } from '../../state/bottle-select-context'
import { Grouping } from '../../types/grouping'
import { AnchorButton } from '../elements'
import DecoratedBottleTitle from './decorated-bottle-title'
import { DEFAULT_BOTTLE_SELECT_CLASS } from './_config'

export default function BottleSelect({
  language,
  slug,
  title,
  media: { src },
  className = '',
  comingSoon = false,
}: Grouping.BottleSelect): ReactElement {
  const { requiresStepTwo } = useBottleSelectState()
  const imageObject = getImage(src)

  if (comingSoon) {
    return (
      <div className={`${DEFAULT_BOTTLE_SELECT_CLASS} ${className} ${DEFAULT_BOTTLE_SELECT_CLASS}--coming-soon`}>
        {imageObject && <GatsbyImage image={imageObject} alt={title} />}
        {!requiresStepTwo && <DecoratedBottleTitle title={title} />}
        <div className="coming-soon-overlay" />
      </div>
    )
  }

  return (
    <AnchorButton
      variant="link"
      href={`/${language}/${slug}`}
      className={`${DEFAULT_BOTTLE_SELECT_CLASS} ${className}`}
    >
      {imageObject && <GatsbyImage image={imageObject} alt={title} />}
      {!requiresStepTwo && <DecoratedBottleTitle title={title} />}
    </AnchorButton>
  )
}
