import React, { MouseEvent } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useBottleSelectDispatch } from '../../state/bottle-select-context'
import { BottleSelectDispatchType } from '../../types/enums'
import { Grouping } from '../../types/grouping'
import { AnchorButton } from '../elements'
import DecoratedBottleTitle from './decorated-bottle-title'
import { DEFAULT_BOTTLE_SELECT_CLASS } from './_config'

export default function BottleSelectMultiple({ primaryBottle, flagshipBottle }: Grouping.BottleSelectMultiple) {
  const dispatch = useBottleSelectDispatch()
  const primaryImage = getImage(primaryBottle.media.src)
  const flagshipImage = getImage(flagshipBottle.media.src)

  const selectBottle = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    dispatch({
      type: BottleSelectDispatchType.enableStepTwo,
      payload: {
        requiresStepTwo: true,
        bottles: [primaryBottle, flagshipBottle],
      },
    })
  }

  return (
    <AnchorButton variant="link" href="/" onClick={selectBottle} className={DEFAULT_BOTTLE_SELECT_CLASS}>
      <div className={`${DEFAULT_BOTTLE_SELECT_CLASS}__multiple-images`}>
        <div className={`${DEFAULT_BOTTLE_SELECT_CLASS}__multiple-images__image animated animated--fade-in-up`}>
          {primaryImage && <GatsbyImage image={primaryImage} alt={primaryBottle.title} />}
        </div>
        <div
          className={`${DEFAULT_BOTTLE_SELECT_CLASS}__multiple-images__image animated animated--fade-in-up animated-delay-2`}
        >
          {flagshipImage && <GatsbyImage image={flagshipImage} alt={flagshipBottle.title} />}
        </div>
      </div>
      <DecoratedBottleTitle title={primaryBottle.title} />
    </AnchorButton>
  )
}
